import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

const mobile = css`
  & + & {
    margin-left: 0;
    margin-top: ${({ theme }) => theme.spacing(2)}px;
  }
`;

export default styled.li`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.33;
  color: ${({ theme }) => transparentize(0.32, theme.colors.dark)};

  & + & {
    margin-left: ${({ theme }) => theme.spacing(6)}px;
  }

  & > a,
  & > button {
    font-size: 1.125rem;
    font-weight: 500;
    text-transform: none;
    color: ${({ theme }) => theme.colors.dark};
    min-width: unset;
    padding: 0;

    .MuiButton-endIcon {
      margin-right: 0;
    }
  }

  & > a {
    text-decoration: none;

    &:hover,
    &:active,
    &.active {
      border-bottom: 2px solid ${({ theme }) => theme.colors.dark};
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${mobile}
  }
`;
