import styled, { css } from 'styled-components';
import { spacing } from '@material-ui/system';

const mobile = css`
  flex-direction: column;
  align-items: flex-start;
`;

export default styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  ${spacing}

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${mobile}
  }
`;
