import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ChevronDownIcon from '@assets/chevron-down.svg';

const StyledChevronDownIcon = styled(({ dropDownOpen, ...props }) => (
  <ChevronDownIcon {...props} />
))`
  transition: transform 0.2s linear;
  transform: ${({ dropDownOpen }) => (dropDownOpen ? 'rotate(180deg)' : 'none')};
`;

export default StyledChevronDownIcon;

StyledChevronDownIcon.propTypes = {
  dropDownOpen: PropTypes.bool.isRequired,
};
