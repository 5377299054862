import React, { useCallback } from 'react';
import { graphql } from 'gatsby';
import { Container, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useColorMode } from '@context/ColorModeContext';
import { SET_COLOR_MODE } from '@context/ColorModeContext/actions';
import Layout from '@c/Layout';
import VisibilitySensor from '@c/VisibilitySensor';
import FeaturedContentGrid from '@c/FeaturedContentGrid';
import Pagination from '@c/Pagination';
import Tags from '@c/Tags';

const Blog = ({
  data: {
    cms: { entries, categories, seomatic, blogMainPage },
  },
  pageContext,
  ...props
}) => {
  const theme = useTheme(),
    [, setBackground] = useColorMode(),
    { categoryId, categorySlug, blogMainPageTitle, currentPage, numPages } = pageContext,
    ctaUrl = `/${blogMainPage.uri}`;

  const handleOnVisible = useCallback(
    id => {
      setBackground({
        type: SET_COLOR_MODE,
        background: { color: theme.colors.bg },
        header: { mode: 'light' },
        visibility: { visibleContentId: id },
      });
    },
    [setBackground, theme.colors.bg]
  );

  return (
    <Layout seomatic={seomatic}>
      <VisibilitySensor id={`blog-${currentPage}`} onVisible={handleOnVisible}>
        <Box mt={{ xs: 12, md: 19.5 }} mb={19.5}>
          <Container>
            <Box mb={{ xs: 8, md: 0 }} pl={{ xs: 0, md: 8 }}>
              <h1>{blogMainPageTitle}</h1>

              <Tags
                mt={{ xs: 4, md: 8 }}
                pagePrefix={ctaUrl}
                tags={categories}
                currTagId={categoryId}
              />
            </Box>

            <FeaturedContentGrid smallGrid ctaUrl={ctaUrl} entries={entries} />

            {numPages > 1 && (
              <Box mt={{ xs: 19.5, md: 32 }}>
                <Pagination
                  pagePrefix={categorySlug ? `${ctaUrl}/kategori/${categorySlug}` : ctaUrl}
                  currentPage={currentPage}
                  pageCount={numPages}
                />
              </Box>
            )}
          </Container>
        </Box>
      </VisibilitySensor>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query(
    $allBlogPostsIds: [cms_QueryArgument]!
    $categoryId: [cms_QueryArgument] = null
    $limit: Int!
    $skip: Int!
    $path: String!
  ) {
    cms {
      categories(group: "blogCategories", relatedTo: $allBlogPostsIds) {
        id
        title
        slug
      }
      blogMainPage: entry(section: "blogMainPage") {
        uri
      }
      entries(
        section: "blog"
        relatedTo: $categoryId
        limit: $limit
        offset: $skip
        orderBy: "postDate desc"
      ) {
        ...blogBaseFields
      }
      seomatic(uri: $path) {
        ...seomaticFields
      }
    }
  }
`;
