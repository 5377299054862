import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useMediaQuery, Button } from '@material-ui/core';
import ChevronDownIcon from './ChevronDownIcon';
import Dash from './Dash';
import Tag from './Tag';
import StyledTags from './Tags.styled';

const Tags = ({ pagePrefix, tags, currTagId, ...props }) => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm')),
    [dropDownOpen, setDropDownOpen] = useState(false),
    currTag = tags.find(({ id }) => parseInt(id) === currTagId);

  const toggleDropDown = useCallback(() => {
    setDropDownOpen(open => !open);
  }, [setDropDownOpen]);

  return (
    <StyledTags {...props}>
      <Tag>
        Kategorier
        {/* TODO: fetch from backend? */}
        {isSmall && (
          <>
            <Dash />
            <Button
              type='button'
              endIcon={<ChevronDownIcon dropDownOpen={dropDownOpen} />}
              onClick={toggleDropDown}
            >
              {currTag?.title || 'Alt'}
              {/* TODO: fetch from backend? */}
            </Button>
          </>
        )}
      </Tag>
      {(!isSmall || dropDownOpen) && (
        <>
          <Tag>
            <Link to={`${pagePrefix}`} className={!currTagId ? 'active' : ''}>
              Alt {/* TODO: fetch from backend? */}
            </Link>
          </Tag>
          {tags.map(tag => (
            <Tag key={tag.id}>
              <Link
                to={`${pagePrefix}/kategori/${tag.slug}`}
                className={currTagId === parseInt(tag.id) ? 'active' : ''}
              >
                {tag.title}
              </Link>
            </Tag>
          ))}
        </>
      )}
    </StyledTags>
  );
};

export default Tags;

Tags.propTypes = {
  pagePrefix: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  currTagId: PropTypes.number,
};

Tags.defaultProps = {
  currTagId: null,
};
